import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";

class reservationDetails5 extends Component {
  render() {
    const { history } = this.props;
    console.log("history history", history);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11} xs={10}>
              <h4 className="pr-reservation-details-title">
                Reservation Details
              </h4>
            </Col>
            <Col className="pl-0" sm={1} xs={2}>
              <img className="close-button" id='close-button' alt="close" src={closeIcon} height={20} width={20} />
            </Col>
          </Row>
          <hr className="mt-0" />
          <div className="mb-3">
            <Row>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-label">
                  SUMMARY:
                </dd>
              </Col>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-value text-uppercase">
                  3 persons, 21th December 2020, 02:00 PM
                </dd>
              </Col>
            </Row>
          </div>

          <div>
            <div className="mb-3">
              <Row>
                <Col sm="6" className="text-left">
                  <Form.Label>FIRST NAME</Form.Label>
                  <Form.Control className="pr-0" type="text" />
                </Col>
                <Col sm="6" className="text-left">
                  <Form.Label>LAST NAME</Form.Label>
                  <Form.Control type="text" />
                </Col>
              </Row>
            </div>
            <div className="mb-1">
              <Row>
                <Col sm="12" className="text-left">
                  <Form.Label>PHONE NUMBER</Form.Label>
                  <MaskedInput
                    className="form-control mobile-input new-input-main pr-0"
                    mask="(111) 111 1111"
                    name="card"
                    size="20"
                    onChange={this._onChange}
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col sm={12}>
                  <Form.Label className="text-left w-100">
                    RESERVATION NOTES
                  </Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col sm="6" className="text-left">
                  <Button
                    variant="outline-pr-primary pt-3 pb-3"
                    block
                    onClick={() => {
                      history.push("/reservation-details4");
                    }}
                  >
                    BACK
                  </Button>
                </Col>
                <Col sm="6" className="text-left">
                  <Button
                    variant="pr-primary pt-3 pb-3"
                    block
                    onClick={() => {
                      history.push("/security-check");
                    }}
                  >
                    RESERVE NOW
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(reservationDetails5);
