import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import Recaptcha from "react-recaptcha";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import { Captcha, captchaSettings } from "reactjs-captcha";

class Securitycheck extends Component {
  constructor(props) {
    super(props);

    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    var Recaptcha = require("react-recaptcha");
  }

  recaptchaLoaded() {
    console.log("captcha loaded succesfully");
  }

  render() {
    // function cap(){
    //     var alpha=['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W',
    //         'X','Y','Z','1','2','3','4','5','6','7','8','9','0','a','b','c','d','e','f','g','h','i',
    //         'j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z',
    //         '!','@','#','$','%','^','&','*','+'

    //     ]

    //     var a=alpha[Math.floor(Math.random()*71)];
    //     var b=alpha[Math.floor(Math.random()*71)];
    //     var c=alpha[Math.floor(Math.random()*71)];
    //     var d=alpha[Math.floor(Math.random()*71)];
    //     var e=alpha[Math.floor(Math.random()*71)];
    //     var f=alpha[Math.floor(Math.random()*71)];

    //     var final=a+b+c+d+e+f;
    //      document.getElementById("capt").value=final;

    // }
    // setTimeout(() => {
    //     cap();

    // }, 1000);

    // function validcap(){

    //     var stg1=document.getElementById("capt").value;
    //     var stg2=document.getElementById("textinput").value;

    //     if(stg1==stg2){
    //         alert("same")
    //     }
    //     else{
    //         alert("not same")
    //     }
    // }
    captchaSettings.set({
      captchaEndpoint:
        "https://your-app-backend-hostname.your-domain.com/simple-captcha-endpoint.ashx",
    });
    const { history } = this.props;
    console.log("history history", history);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11}>
              <h4 className="pr-reservation-details-title">Security Check</h4>
            </Col>
            <Col className="pl-0" sm={1}>
              <img className="close-button" id='close-button' alt="close" src={closeIcon} height={20} width={20} />
            </Col>
          </Row>
          <hr className="mt-0" />
          {/* <div className="mb-3">
                        <Row>
                            <Col sm="12" className="text-left">
                                <dd className="text-12 pr-reservation-success-label try-another">
                                    Enter both words below, seprated by a space.
                                    <br/>Can't read this?  <a href="#" title="try another"> Try another.</a>
                                </dd>
                            </Col>
                        </Row>
                    </div> */}

          <div>
            <div className="mb-3">
              <Row>
                <Col sm="12" className="text-left">
                  {/* <span className="try-new-au">Try an audio capture.</span> */}
                </Col>
                <Col sm="12" className="text-left">
                  {/* <input type="text" readOnly id="capt" className="captcha-img"/> */}

                  {/* <input  type="text"  readOnly id="capt"/>
      <input  type="text" id="textinput" /> */}

                  <Recaptcha
                    sitekey="6Lds5ooaAAAAAM_hur5lSF8lP2WisLjo9ZPSXEVI"
                    render="explicit"
                    onloadCallback={this.recaptchaLoaded}
                  />
                </Col>
              </Row>
            </div>
            {/* <div className="mb-4">
                            <Row>
                                <Col sm="4" className="text-left type-in-box">
                                    <Form.Label>Type in the Box:</Form.Label>
                                </Col>
                                <Col sm="8" className="text-left">
                                    <input as={Form.Control} type="text" id="textinput" />
                                </Col>
                            </Row>
                        </div> */}

            <div>
              <Row>
                <Col sm="6" className="text-left">
                  <Button
                    variant="outline-pr-primary pt-3 pb-3"
                    block
                    onClick={() => {
                      history.push("/security-check");
                    }}
                  >
                    BACK
                  </Button>
                </Col>
                <Col sm="6" className="text-left">
                  {/* <Button variant="pr-primary pt-3 pb-3" block onClick={() => {history.push("/reservation-success")&&validcap()}}>DONE</Button> */}
                  <Button variant="pr-primary pt-3 pb-3">DONE</Button>
                </Col>
              </Row>
            </div>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(Securitycheck);
