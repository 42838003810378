import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { withRouter } from "react-router";
// import Container from 'react-bootstrap/Container';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import Recaptcha from "react-recaptcha";
import { InputTextarea } from "primereact/inputtextarea";
import { API } from "../common/api";
import InputMask from "react-input-mask";
// import PhoneInput from 'react-phone-number-input'
import axios from "axios";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import close from "../common/close";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { seldate } from './reservationBasic'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
const FormErrorMsg = styled.span`
  color: red;
  float: right;
  font-size: 12px;
`;
var retrievedObject = JSON.parse(
  sessionStorage.getItem("reservationDetails")
);
class ReservationDetails extends Component {
  constructor(props) {
    super(props);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    
    this.state = {
      formData: "",
      otpSend: false,
      phoneError: "",
      captcha: false,
    };
  }
  handleCloseButton = () => {
    let closeButton = document.getElementById("close-button")
    let widgetType=sessionStorage.getItem("widget_type")||"inline";
    if (widgetType === 'inline') {
      closeButton.style.display = 'none'
    }
    else {
      closeButton.style.display = 'initial'
    }
  }
  verifyBack = (response) => {
    if (response) {
      this.setState({
        captcha: true,
      });
    }
  };
  recaptchaLoaded() {
    console.log("captcha loaded succesfully");
  }

  handleSubmit = (history, values) => {
    // console.log(`values`, values);
    // return;

    var shiftId = JSON.parse(sessionStorage.getItem("shiftId"));
    const {
      date,
      people,
      shift,
      slotSize,
      timeSlot,
      otpEnabled,

      rId,
      depositeAmount,
      depositeMinGuest,
      rKey,
    } = this.props.location.state;
    var testObject = {
      firstName: values.firstname,
      lastName: values.lastname,
      phone: (retrievedObject?.phone || this.state.formData.phone),
      email: values.email,
      notes: values.notes,
    };
    console.log("submitted data : ", testObject);
    sessionStorage.setItem("reservationDetails", JSON.stringify(testObject));
    let validateOtp;
    let reserved_to = moment(timeSlot, "hh:mm a")
      .add(slotSize, "minutes")
      .format("HH:mm:ss");
    const bodyData = {
      first_name: values.firstname,
      last_name: values.lastname,
      mobile: (retrievedObject?.phone || this.state.formData.phone),
      email: values.email,
      shift_id: shiftId,
      rest_id: rId,
      booked_date: date,
      no_of_guests: people,
      reserved_to: reserved_to,
      reserved_from: timeSlot,
      note: values.notes || "",
    };
    if (otpEnabled) {
      validateOtp = axios
        .post(`${process.env.REACT_APP_BASEURL}/otp/validate`, null, {
          params: {
            mobile: this.state.formData.phone,
            otp: this.state.formData.otp,
          },
        })
        .then((res) => {
          history.push({
            pathname: "/reservation-confirmation",
            state: {
              bodyData: bodyData,
              guestData: {
                depositeAmount: depositeAmount,
                depositeMinGuest: depositeMinGuest,
              },
              rKey: rKey,
            },
          });
          // history.go(0)

        })
        .catch((err) => {
          // console.log(err);
          this.setState({
            phoneError: err.response?.data.detail,
            otpSend: false,
          });
        });
    } else {
      history.push({
        pathname: "/reservation-confirmation",
        state: {
          bodyData: bodyData,
          guestData: {
            depositeAmount: depositeAmount,
            depositeMinGuest: depositeMinGuest,
          },
          rKey: rKey,
        },
      });
      // history.go(0)
    }
    if (validateOtp || !otpEnabled) {
      // if (people >= depositeMinGuest) {
      //   console.log("stripe payment");
      // } else {
      // }
      // console.log("bodyData", bodyData);
      // axios
      //   .post(`${API}/reservation/add_reservation_by_customer`, bodyData)
      //   .then((res) => {});
    }
  };
  onClickOtpSend = (phone) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}/otp/sendotp`, null, {
        params: {
          mobile_number: phone,
        },
      })
      .then((res) => {
        this.setState({ otpSend: true, phoneError: "" });
      })
      .catch((err) => {
        // this.setState({ phoneError: err.response.data.detail });
      });
  };

  componentDidMount()
  {
    this.handleCloseButton();
  }
  render() {
    console.log("Form data", this.state.formData)
    // handler.setCentralState({ foo: "bar" });

    const { history } = this.props;
    const {
      date,
      people,
      shift,
      slotSize,
      timeSlot,
      otpEnabled,
      shiftId,
      rId,
      rKey,
    } = this.props.location.state;
    let otp = otpEnabled;
    const validationSchema = yup.object().shape({
      firstname: yup.string().required("Required"),
      lastname: yup.string().required("Required"),
      email: yup.string().required("Required"),
      // phone: yup.string().required("Required"),
    });
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11} xs={10} className="padding-none">
              <h4 className="pr-reservation-details-title">
                Reservation Details
              </h4>
            </Col>
            <Col className="pl-0 padding-none" xs={2} sm={1}>
              <img
              className="close-button"
              id='close-button'
                src={closeIcon}
                alt=""
                height={20}
                width={20}
                onClick={() => {
                  close();
                }}
              />
            </Col>
          </Row>
          <hr className="mt-0" />
          <div className="mb-1">
            <Row>
              <Col sm="12" className="text-left padding-none">
                <dd className="text-bold text-12 pr-reservation-success-label">
                  SUMMARY:
                </dd>
              </Col>
              <Col sm="12" className="text-left padding-none">
                <dd className="text-12 pr-reservation-success-value text-uppercase">
                  {people} PERSONS, {date}, {timeSlot}
                </dd>
              </Col>
            </Row>
          </div>

          <Formik
            initialValues={{
              firstname: retrievedObject?.firstName,
              lastname: retrievedObject?.lastName,
              phone: retrievedObject?.phone,
              email: retrievedObject?.email,
              otp: "",
              notes: retrievedObject?.notes,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log(`values`, values);
              this.handleSubmit(history, values);
            }}
          // enableReinitialize={true}
          >
            {({
              errors,
              status,
              touched,
              handleSubmit,
              handleChange,
              values,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <Row>
                      <Col sm="6" className="text-left padding-left">
                        <Form.Label>
                          FIRST NAME &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ErrorMessage
                            style={{ float: "right" }}
                            name="firstname"
                            component={FormErrorMsg}
                          />
                        </Form.Label>
                        <Field
                          maxLength="15"
                          name="firstname"
                          // value={this.state.formData.firstName}
                          // onChange={(e) => {
                          //   handleChange(e);
                          //   // and do something about e
                          //   this.setState({
                          //     formData: {
                          //       ...this.state.formData,
                          //       firstName: e.target.value,
                          //     },
                          //   });
                          // }}
                          as={Form.Control}
                          className={
                            errors.firstname && touched.firstname
                              ? " is-invalid"
                              : "pr-0"
                          }
                          type="text"
                        />
                      </Col>
                      <Col sm="6" className="text-left padding-right">
                        <Form.Label>
                          LAST NAME &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ErrorMessage
                            name="lastname"
                            component={FormErrorMsg}
                          />
                        </Form.Label>
                        <Field
                          type="text"
                          as={Form.Control}
                          className={
                            errors.lastname && touched.lastname
                              ? " is-invalid"
                              : "pr-0"
                          }
                          name="lastname"
                        // value={this.state.formData.lastName}
                        // onChange={(e) => {
                        //   handleChange(e);
                        //   // and do something about e
                        //   this.setState({
                        //     formData: {
                        //       ...this.state.formData,
                        //       lastName: e.target.value,
                        //     },
                        //   });
                        // }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div style={{ marginTop: "15px" }} className="mb-1">
                    <Row>
                      <Col
                        sm={!otp ? "11s" : "7"}
                        className="text-left padding-left "
                      >
                        <Form.Label style={{ width: "315px" }}>
                          PHONE NUMBER &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <ErrorMessage name="phone" component={FormErrorMsg} /> */}
                          <span id="phone"
                            style={{ display: "none" }}
                            className="float-right">Required</span>
                        </Form.Label>
                        <div id="pinp" style={{ width: "100%", borderRadius: "3px" }} className="code">
                          <Field
                            type="tel"
                            required
                            as={PhoneInput}
                            defaultErrorMessage="Required"
                            onBlur={(e) => {
                              console.log("on blur phone", e.target.value)
                              if (e.target.value?.length <= 3) {
                                let p = document.getElementById('phone')
                                let pi = document.getElementById('pinp')
                                p.style.color = "red"
                                p.style.display = "initial"
                                pi.style.border = "1px solid red"
                              }
                              else {
                                let p = document.getElementById('phone')
                                let pi = document.getElementById('pinp')
                                p.style.display = "none"
                                pi.style.border = "none"

                              }
                            }}
                            onlyCountries={['gb', 'in']}
                            country={'gb'}
                            className={
                              errors.phone && touched.phone
                                ? " is-invalid mobile form-control"
                                : "pr-0 mobile form-control"
                            }

                            name="phone"
                            value={retrievedObject?.phone ? retrievedObject?.phone : this.state.formData.phone}
                            onChange={(e) => {
                              // call the built-in handleChange for formik
                              // handleChange(e);
                              // and do something about e
                              if (e?.length <= 11) {
                                let p = document.getElementById('phone')
                                let pi = document.getElementById('pinp')
                                p.style.color = "red"
                                p.style.display = "initial"
                                pi.style.border = "1px solid red"
                              }
                              else {
                                let p = document.getElementById('phone')
                                let pi = document.getElementById('pinp')
                                p.style.display = "none"
                                pi.style.border = "none"

                              }
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  phone: '+' + e,
                                },
                              }); // Update a DOM element on this function
                            }}
                          /></div>
                      </Col>
                      {otp ? (
                        <Col sm="5" className="text-left padding-right">
                          <Form.Label>
                            OTP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <ErrorMessage name="otp" component={FormErrorMsg} />
                          </Form.Label>
                          <Field
                            type="text"
                            maxLength="6"
                            as={Form.Control}
                            value={this.state.formData.otp}
                            className={
                              errors.otp && touched.otp ? " is-invalid" : ""
                            }
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  otp: e.target.value,
                                },
                              });
                            }}
                            name="otp"
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                  <div className="mb-1">
                    <Row>
                      <Col className="pl-0 pr-0">
                        <Form.Label className="text-left w-100">
                          EMAIL &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ErrorMessage name="email" component={FormErrorMsg} />
                        </Form.Label>
                        <Field
                          type="email"
                          as={Form.Control}
                          className={
                            errors.email && touched.email
                              ? " is-invalid"
                              : "pr-0"
                          }
                          name="email"
                        // value={this.state.formData.lastName}
                        // onChange={(e) => {
                        //   handleChange(e);
                        //   // and do something about e
                        //   this.setState({
                        //     formData: {
                        //       ...this.state.formData,
                        //       lastName: e.target.value,
                        //     },
                        //   });
                        // }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-3">
                    <Row>
                      <Col sm={12} className="padding-none">
                        <Form.Label className="text-left w-100">
                          RESERVATION NOTES &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <ErrorMessage name="notes" component={FormErrorMsg} />
                        </Form.Label>
                        <Field
                          as={InputTextarea}
                          autoResize
                          name="notes"
                          // value={this.state.formData.notes}
                          // onChange={(e) => {
                          //   handleChange(e);
                          //   // and do something about e
                          //   this.setState({
                          //     formData: {
                          //       ...this.state.formData,
                          //       notes: e.target.value,
                          //     },
                          //   });
                          // }}
                          className="textareatt"
                        />
                      </Col>
                    </Row>
                  </div>
                  {!otp ? (
                    <div style={{ minHeight: "78px" }} className="mb-3">
                      <Row>
                        <Recaptcha
                          sitekey="6LfcQfEaAAAAACpkzZ3vROuIB9VgPL1WXM72sCAM"
                          render="explicit"
                          // type="image"

                          onloadCallback={this.recaptchaLoaded}
                          verifyCallback={this.verifyBack}
                        />
                      </Row>
                    </div>
                  ) : null}
                  <div>
                    <Row>
                      <Col
                        sm="12"
                        md="6"
                        className="text-left padding-left bsubmit"
                      >
                        <Button
                          block
                          variant="outline-pr-primary pt-3 pb-3"
                          onClick={() => {
                            // history.push(`/home/${rKey}`);
                            var testObject = {
                              firstName: values.firstname,
                              lastName: values.lastname,
                              phone: (retrievedObject?.phone || this.state.formData.phone),
                              email: values.email,
                              notes: values.notes,
                            };
                            console.log("back values : ", testObject);
                            // sessionStorage.setItem('back',true)
                            sessionStorage.setItem("reservationDetails", JSON.stringify(testObject));
                            history.goBack();
                            // setTimeout(() => {
                            //   history.go(0);

                            // }, 200);
                            // seldate();
                          }}
                        >
                          BACK
                        </Button>
                      </Col>
                      <Col
                        sm="12"
                        md="6"
                        className="text-left padding-right rsubmit"
                      >
                        {/* <Button block variant="pr-primary pt-3 pb-3" onClick={() => { history.push("/reservation-details2")}} >RESERVE NOW</Button> */}
                        {/* <Button
                        type="submit"
                        block
                        variant="pr-primary pt-3 pb-3"
                        // onClick={() => this.onClickReserveNow(history)}
                        disabled={
                          this.state.formData.otp || !otpEnabled ? false : true
                        }
                      >
                        RESERVE NOW
                      </Button> */}
                        {this.state.otpSend || !otpEnabled ? (
                          <Button
                            type="submit"
                            block
                            variant="pr-primary pt-3 pb-3"
                            // onClick={() => this.onClickReserveNow(history)}
                            disabled={
                              (this.state.formData.otp || this.state.captcha) &&
                                values.firstname &&
                                values.lastname &&
                                (retrievedObject?.phone || this.state.formData.phone)
                                ? false
                                : true
                            }
                          >
                            RESERVE NOW
                          </Button>
                        ) : (
                          <Button
                            block
                            variant="pr-primary pt-3 pb-3"
                            disabled={this.state.formData.phone ? false : true}
                            //   type="submit"
                            onClick={() => this.onClickOtpSend(this.state.formData.phone)}
                          >
                            Send OTP
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </form>
              </>
            )}
          </Formik>

        </center>

      </Layout>
    );
  }
}

export default withRouter(ReservationDetails);
