const nacl = require('tweetnacl')
const utils = require('tweetnacl-util')

const encrypt = (props) => {

    const encodeBase64 = utils.encodeBase64
    // Our nonce must be a 24 bytes Buffer (or Uint8Array)
    const nonce = nacl.randomBytes(24)
    const key = process.env.REACT_APP_E_KEY
    // Our secret key must be a 32 bytes Buffer (or Uint8Array)
    const secretKey = Buffer.from(key, 'utf8')

    // Make sure your data is also a Buffer of Uint8Array
    // let lv = {
    //     name:"vinay kumar",
    //     position:"Front-end Developer",
    //     EmployeeId:"11"
    // }
    // console.log(JSON.stringify(lv))
    const secretData = Buffer.from(JSON.stringify(props), 'utf8')

    const encrypted = nacl.secretbox(secretData, nonce, secretKey)

    // We can now store our encrypted result and our nonce somewhere
    const result = `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`
    return result

}
export default encrypt