import React, { Component } from 'react';
import { Fragment } from 'react';
import Card from "react-bootstrap/Card";
import { Route } from "react-router-dom";
import verifyOTP from "../../pages/verifyOTP.js";
import logo from '../../assets/images/logo icon.svg';


const Layout = React.memo(props => {
    const { children, pathName } = props
    console.log("pathname ----------", pathName)

    let cardClassName = ""

    if (pathName.toString() === "/") {
        cardClassName = "pr-main-body"
    }

    if (pathName.toString() === "/mobile-verification") {
        cardClassName = "pr-phone-verification-body"
    }

    if (pathName.toString() === "/verify-mobile") {
        cardClassName = "pr-valid-otp-body"
    }

    if (pathName.toString() === "/payment-success") {
        cardClassName = "pr-payment-success-body"
    }
    if (pathName.toString() === "/reservation-success") {
        cardClassName = "pr-reservation-success-body"
    }
    if (pathName.toString() === "/reservation-details") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/reservation-details2") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/reservation-details3") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/payment-informations") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/choose-times") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/reservation-details4") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/reservation-details5") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/security-check") {
        cardClassName = "pr-reservation-details-body"
    }
    if (pathName.toString() === "/contact-us") {
        cardClassName = "pr-reservation-details-body"
    }

    return <Fragment>
        <span className="centered">
            <Card className="pr-main-card mt-0 text-center">
                <Card.Body>
                    <div className={cardClassName}>
                        {children}
                    </div>
                    <div
                        // style={{
                        //     position: "absolute",
                        //     bottom: "15px",
                        //     left: "0",
                        //     right: "0",
                        // }}
                        className="mt-3 footerlogo">
                        <h4 style={{ fontSize: "12px", fontWeight: "500" }}>Powered by</h4>
                        <img width="90px" src={logo} alt="logo" />
                    </div>
                </Card.Body>
            </Card>
        </span>

        {/* {!this.state.show ?
        <span onClick={()=>{this.setState({show:true});console.log("clicked",this.state.show)}} className="BookTable">Book a Table</span>
        :null
        } */}
    </Fragment>
})

export default Layout;