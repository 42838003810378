import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import loader from '../assets/images/icons8-dots-loading.gif'
import handleStripePayment from "./Stripe.js";
import axios from "axios";
import moment from "moment";
import { API } from "../common/api";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import close from "../common/close";

toast.configure();

const FormErrorMsg = styled.span`
  color: red;
  float: right;
  font-size: 12px;
`;
class ReservationConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      load:false
    };
  }

  handleCloseButton = () => {
    let closeButton = document.getElementById("close-button")
    if(closeButton)
    {
    let widgetType=sessionStorage.getItem("widget_type")||"inline";
    if (widgetType === 'inline') {
      closeButton.style.display = 'none'
    }
    else {
      closeButton.style.display = 'initial'
    }
  }
  }

  componentDidMount()
  {
    this.handleCloseButton();
  }
  handleClickProceed = (
    no_of_guests,
    depositeMinGuest,
    history,
    APIBody,
    booked_date,
    reserved_from,
    depositeAmount
  ) => {
    const { first_name, last_name, mobile, rest_id,shift_id,note } = APIBody;
    let success = false;
    console.log("proceed clicked")
    console.log("deposit amount", depositeAmount)
    const { rKey } = this.props.location.state;

    if (no_of_guests <= depositeMinGuest) {
      this.setState({ load:true });
      axios
        .post(
          `${process.env.REACT_APP_BASEURL}/reservation/add_reservation_by_customer`,
          APIBody
        )

        .then((res) => {
          // console.log("res", res);
          // sessionStorage.removeItem("reservationBasic");
          // sessionStorage.removeItem("reservationDetails");
          // sessionStorage.removeItem("shiftId");
          console.log("booking resp:", res)
          if (res.data.success) {
            history.push({
              pathname: "/reservation-success",
              state: {
                firstName: first_name,
                lastName: last_name,
                date: booked_date,
                time: reserved_from,
                mobile: mobile,
                guest: no_of_guests,
                restId: rest_id,
                rKey: rKey,
                token: res.data.queue_token_no,
                reservationId:res.data.reservation_id
              },
            });
            this.setState({ load:false });
          }
        })
        .catch((err) => {
          // sessionStorage.removeItem("reservationBasic");
          // sessionStorage.removeItem("reservationDetails");
          // sessionStorage.removeItem("shiftId");
          this.setState({ load:false });
          this.setState({ error: err.response?.data.detail });
          toast.error(err.response?.data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    else {
      // handleStripePayment({
      //   firstName: first_name,
      //   lastName: last_name,
      //   date: booked_date,
      //   time: reserved_to,
      //   mobile: mobile,
      //   guest: no_of_guests,
      // });
      // console.log("success");
      history.push({
        pathname: "/payment-informations",
        state: {
          firstName: first_name,
          lastName: last_name,
          date: booked_date,
          time: reserved_from,
          mobile: mobile,
          email: this.props.location.state.bodyData.email,
          guest: no_of_guests,
          restId: rest_id,
          shiftId:shift_id,
          depositeAmount: depositeAmount,
          note:note,
          rKey: rKey,
        },
      });
    }
    // console.log("success value", success);
  };
  render() {
    const { history } = this.props;
    const { depositeAmount, depositeMinGuest } =
      this.props.location.state.guestData;
    const {
      first_name,
      last_name,
      mobile,
      email,
      shift_id,
      rest_id,
      booked_date,
      no_of_guests,
      reserved_from,
      reserved_to,
      note,
    } = this.props.location.state.bodyData;
    const { rKey } = this.props.location.state;
    console.log("hthis.props.location.state", this.props.location.state);
    const APIBody = {
      first_name: first_name,
      last_name: last_name,
      mobile: mobile,
      email: email,
      shift_id: shift_id,
      rest_id: rest_id,
      booked_date: moment(booked_date, "Do MMMM YYYY").format("YYYY-MM-DD"),
      no_of_guests: no_of_guests,
      reserved_from: moment(reserved_from, "hh:mm a").format("HH:mm:ss"),
      reserved_to: reserved_to,
      note: note,
      card_number: "string",
      exp_month: 0,
      exp_year: 0,
      cvc: "string",
      amount: 0
    };
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11} xs={10} className="padding-left">
              <h4 className="pr-reservation-details-title">
                Reservation Details
              </h4>
            </Col>
            <Col className="pl-0 padding-right" sm={1} xs={2}>
              <img
              className="close-button"
              id='close-button'
                src={closeIcon}
                alt="close"
                height={20}
                width={20}
                onClick={() => {
                  close();
                }}
              />
            </Col>
          </Row>
          <hr className="mt-0" />
          <FormErrorMsg>{this.state.phoneError}</FormErrorMsg>
          <div className="mb-2">
            <Row className="mb-3">
              <Col sm="5" className="text-left padding-left">
                <dd className="text-14 pr-reservation-success-label text-bold">
                  Booking Details
                </dd>
                <span className="m-t-1 address">
                  {first_name} {last_name} <br /> {mobile}
                  <br />
                  Guests:{no_of_guests}{" "}
                </span>
              </Col>
              <Col sm="7" className="text-right right-side-date padding-right">
                <dd className="text-12 pr-reservation-success-value text-uppercase">
                  {booked_date}, {reserved_from}
                </dd>
              </Col>
            </Row>
          </div>

          <div>
            {no_of_guests > depositeMinGuest && (
              <>
                {" "}
                <div className="mb-0">
                  <Row>
                    <Col sm="6" xs="6" className="text-left padding-left">
                      <dd className="text-14 text-bold">Reservation Amount</dd>
                      <span className="small-text">(Non-Refundable)</span>
                    </Col>
                    <Col sm="6" xs="6" className="text-right padding-right">
                      <span className="small-text">
                        £{depositeAmount * no_of_guests}
                      </span>
                    </Col>
                  </Row>
                </div>
                {/* <div className="mb-0">
              <Row>
                <Col sm="6" className="text-left padding-left">
                  <Form.Label>Tax</Form.Label>
                </Col>
                <Col sm="6" className="text-right padding-right">
                  <span className="small-text">£0.11</span>
                </Col>
              </Row>
            </div> */}
                <div className="mb-0">
                  <Row>
                    <Col sm="6" xs="6" className="text-left padding-left">
                      <dd className="text-14 text-bold">Net Amount Payable</dd>
                    </Col>
                    <Col sm="6" xs="6" className="text-right padding-right">
                      <span className="small-text">
                        £{depositeAmount * no_of_guests}
                      </span>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col sm={12} className="padding-none">
                      <div className="yeallow-alert-main">
                        <span className="alert-icon-main"></span>
                        Please note, to avoid seating rush and to prevent the
                        hassle,the reservation amount is non-refundable incase
                        of any cancellation or by any reason.
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            <div>
              <Row>
                <Col sm="6" className="text-left padding-left">
                  <Button
                  disabled={this.state.load}
                    variant="outline-pr-primary pt-3 pb-3"
                    block
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    BACK
                  </Button>
                </Col>
                <Col sm="6" className="text-left padding-right">
                  <Button
                    variant="pr-primary spinnerBtn"
                    block
                    style={{padding:this.state.load?"10px":"16px 20px",fontSize:this.state.load?"14px":"11px"}}
                    disabled={this.state.load}
                    onClick={
                      () =>
                        this.handleClickProceed(
                          no_of_guests,
                          depositeMinGuest,
                          history,
                          APIBody,
                          booked_date,
                          reserved_from,
                          depositeAmount
                        )
                      // no_of_guests>= depositeMinGuest?
                      // handleStripePayment():
                      // history.push("/payment-success");
                    }
                  >
                    {this.state.load?<><i class="fas fa-circle-notch spinner"></i>Processing...</>:"PROCEED"}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(ReservationConfirmation);
