/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useEffect, useState, useRef, memo, useCallback } from "react";
import Button from "react-bootstrap/Button";
// import MaskedInput from "react-maskedinput";
import { useHistory, withRouter } from "react-router";
// import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import loadingGif from "../assets/images/icons8-dots-loading.gif";
import Slider from "react-slick";
// import { InputNumber } from 'primereact/inputnumber';
import { Link } from "react-router-dom";
import moment from "moment";
import { API } from "../common/api";
import axios from "axios";
import close from "../common/close";
import { Dropdown } from 'primereact/dropdown';
import Select from 'react-select';
import ThemeHandler from '../Theme';

const ReservationDetails4 = (props) => {
  //dynamic date rendering


  var retrievedObject = JSON.parse(sessionStorage.getItem("reservationBasic"));
  var retrievedShiftId = sessionStorage.getItem("shiftId");
  var retrievedtimeSlot = JSON.parse(sessionStorage.getItem("timeSlot"));
  var retrievedShift = JSON.parse(sessionStorage.getItem("Shift"));
  var currentBranch = JSON.parse(sessionStorage.getItem('currentBranch'))
  var selectedBranchNm = JSON.parse(sessionStorage.getItem('selectedBranch'))
  var BranchList = JSON.parse(sessionStorage.getItem('branchNames'))
  var branches = JSON.parse(sessionStorage.getItem('branches'))

  currentBranch = currentBranch ? currentBranch : null
  const history = useHistory();

  var BranchValue = useRef()
  BranchValue.current = retrievedObject?.branch && retrievedObject?.branch;

  // setBranchNames(branchNamesTemp);
  // setBranches(branches);
  // setSelectedBranchName(branchNamesTemp[0]);
  // setSelectedBranch(branches[0]);

  const [opDays, setOpDays] = useState([]);
  const [dates, setDates] = useState([]);
  const [chooseShift, setChooseShift] = useState(retrievedShift ? retrievedShift : []);
  const [chooseBranch, setBranches] = useState(branches ? branches : []);
  const [branchNames, setBranchNames] = useState(BranchList ? BranchList : []);
  const [singleShift, setsingleShift] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(currentBranch ? currentBranch : {});
  const [selectedBranchName, setSelectedBranchName] = useState(selectedBranchNm ? selectedBranchNm : {});
  const [selectedShift, setSelectedShift] = useState([]);
  const [shift, setShift] = useState(retrievedObject?.shift);
  const [slotSize, setSlotSize] = useState();
  const [timeSlot, setTimeSlot] = useState(retrievedtimeSlot);
  const [otpEnabled, setOtpEnabled] = useState();
  const [shiftId, setShiftId] = useState(retrievedShiftId);
  const [rId, setRId] = useState();
  const [peopleLimit, setPeopleLimit] = useState();
  const [depositeAmount, setDepositeAmount] = useState("");
  const [depositeMinGuest, setDepositeMinGuest] = useState("");
  const [hotelEmail, setHotelEmail] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [rKey, setRKey] = useState("");
  const [hotelMono, setHotelMono] = useState();
  const [duration_btn_bookings, setDuration_btn_bookings] = useState();
  const [holidays, setHolidays] = useState();
  const [holidayBlock, setHolidayBlock] = useState(false);
  const [holidayTimeBlock, setHolidayTimeBlock] = useState();
  // const [scheduledTime, setScheduledTime] = useState();
  const [fullTimeBlock, setFullTimeBlock] = useState(false);
  const [weekSchedule, setWeekSchedule] = useState({})

  const [guestlenght, setGuestlenght] = useState([]);
  const [date, setDate] = useState(retrievedObject?.date);
  const [time, setTime] = useState(retrievedObject?.timeSlot);
  const [dateSelected, setDateSelected] = useState(retrievedObject?.timeSlot ? true : false);
  const [moreGuest, setMoreGuest] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [inlineWidget, setInlineWidget] = useState("inline")

  let timesSlot = useRef([]);
  let cities = useRef([]);
  var restId = useRef();//props.match.params?.restId;

  var peopleValue = useRef()
  peopleValue.current = retrievedObject?.people ? { label: retrievedObject?.people, value: retrievedObject?.people } : { label: 2, value: 2 };
  const [people, setPeople] = useState(peopleValue.current);
  const [selectedPeople, setSelectedPeople] = useState(peopleValue.current);

  let closeButton
  // console.log(`process.env.REACT_APP_BASEURL`, process.env.REACT_APP_BASEURL);
  const orgId = props.match.params?.orgId;

  const renderBranchData = useCallback((branch) => {

    console.log("current branch: ", branch);
    // const shifts = branch.shift?.filter(
    //   (item) => !item.is_deleted
    // );
    // setChooseShift(shifts);
    // if ((shifts).length === 1) {
    //   console.log("shifts length : ", (shifts).length)
    //   setsingleShift(shifts[0])
    // }
    setOpDays([])
    setRId(branch.rest_id);
    setOtpEnabled(branch.is_otp_enabled);
    setSlotSize(branch.booking_period);
    setPeopleLimit(branch.party_size_limit);
    setDepositeAmount(branch.deposit_amount);
    setDepositeMinGuest(branch.deposit_min_guest);
    setHotelEmail(branch.restaurant_email);
    setHotelName(branch.restaurant_name);
    setRKey(branch.r_key);
    setHotelMono(branch.restaurant_mobile);
    setWeekSchedule(branch.week_schedule)
    
    console.log("week data : ", branch.week_schedule)
    setDuration_btn_bookings(
      branch.duration_btn_bookings
    );
    setHolidays(branch.holidays);
    let pp = branch.party_size_limit;
    setGuestlenght([]);
    for (let i = 1; i <= pp; i++) {
      //  cities.push({ name: i })
      setGuestlenght(prev => [...prev, { value: i, label: i }])
      // console.log(i, guestlenght)
    }

    for (var i = 0; i <= 7; i++) {
      let today = moment().add(i, "days");
      let daysopen = moment(today).format("MMM D ddd");
      let fullDate = moment(today).format("Do MMMM YYYY");
      let day = moment(today).format("dddd");
      // let dateForCompare = moment(today).format("DD-MM-YYYY");
      // console.log("today", dateForCompare)
      // console.log("daysopen", daysopen);
      switch (day.toLowerCase()) {
        case "monday":

          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.monday },
          ]);

          break;
        case "tuesday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.tuesday },
          ]);

          break;
        case "wednesday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.wednesday },
          ]);

          break;
        case "thursday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.thursday },
          ]);

          break;
        case "friday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.friday },
          ]);


          break;
        case "saturday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.saturday },
          ]);

          break;
        case "sunday":
          setOpDays((prev) => [
            ...prev,
            { Day: day.toLowerCase(), Days: daysopen, fullDate: fullDate, directDate: today, time: branch.week_schedule?.sunday },
          ]);

          break;

        default:
          break;
      }

      setDates((prev) => [...prev, { dates: today }]);
    }

  }, []);


  const HandleCloseButton = (props) => {
    let closeButton = document.getElementById("close-button")
    if (props === 'inline') {
      closeButton.style.display = 'none'
    }
    else {
      closeButton.style.display = 'initial'
    }
  }

  useEffect(() => {

    if (currentBranch === null) {
      setIsLoading(true)
      axios
        .get(
          `${process.env.REACT_APP_BASEURL}/restaurant/restaurants/${orgId}`
        )
        .then((resp) => {
          let branches = resp.data;
          let branchNamesTemp = [];
          for (let i = 0; i < branches.length; i++) {
            branchNamesTemp.push({ 'value': i, 'label': branches[i].restaurant_name });
          }
          ThemeHandler(branches[0].booking_widget_settings.theme.primarycolor)
          setInlineWidget(branches[0].booking_widget_settings.display_type)
          HandleCloseButton(branches[0].booking_widget_settings.display_type)
          
          // sessionStorage.setItem('widget_template', JSON.stringify(branches[0].booking_widget_settings))

          setBranchNames(branchNamesTemp);
          setBranches(branches);
          setSelectedBranchName(branchNamesTemp[0]);
          setSelectedBranch(branches[0]);
          restId.current = branches[0].r_key;
          sessionStorage.setItem('currentBranch', JSON.stringify(branches[0]))
          sessionStorage.setItem('selectedBranch', JSON.stringify(branchNamesTemp[0]))
          sessionStorage.setItem('branches', JSON.stringify(branches))
          sessionStorage.setItem('branchNames', JSON.stringify(branchNamesTemp))
          sessionStorage.setItem('orgId', JSON.stringify(branches[0].org_id))
          sessionStorage.setItem('widget_type', branches[0].booking_widget_settings.display_type)
          renderBranchData(branches[0])
          setIsLoading(false);

          // axios
          // .get(
          //   `${process.env.REACT_APP_BASEURL}/restaurant/restaurant_details/${restId}`
          // )
          // .then((res) => {
          //   console.log("restaurant details: ", res);
          // });
        });
    }
  }, []);

  useEffect(() => {
    if (currentBranch) {
      renderBranchData(currentBranch)
      ThemeHandler(currentBranch.booking_widget_settings.theme.primarycolor)
      HandleCloseButton(currentBranch.booking_widget_settings.display_type)
    }
  }, [])
  // console.log("moment dates : ", chooseShift);

  //dynamic time rendering

  //adding guests

  const sub = () => {
    if (people > 1) {
      setPeople(people - 1);
    }
  };
  const add = () => {
    if (people < peopleLimit) {
      setPeople(people + 1);
    } else setMoreGuest(true);
  };





  // state = { index: 0 };
  //   next = () => {
  //     this.slider.slickNext();
  //   };
  //   previous = () => {
  //     this.slider.slickPrev();
  //   };
  //   beforeChange = (prev, next) => {
  //     this.setState({ index: next });
  //   };
  var settings = {
    // slidesToShow: 5,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 2,
    initialSlide: 0,
    slidesToShow: opDays.length > 5 ? 5 : opDays.length,
  };
  const onDateSelected = useCallback((date) => {
    // console.log(`date`, moment(date.directDate).format("DD-MM-YYYY"));
    let shifts = weekSchedule[date.Day].shifts;
    setChooseShift(shifts);
    setShift('')
    setTimeSlot(null);
    setDate(date.fullDate);
    setHolidayBlock(false);
    setFullTimeBlock(false);
    setHolidayTimeBlock({});
    console.log("schedule : ", date)
    if (date.time.isOpen === false) {
      setFullTimeBlock(true)
    }
    console.log("Timeslot", {
      closed_from: moment(date.time.openTime, "HH:mm a").format("hh:mm:ss"),
      closed_to: moment(date.time.closingTime, "HH:mm a").format("hh:mm:ss"),
    })
    // console.log("test", moment(holidays[7].start_date));
    // console.log("test", date.directDate);
    // var time = moment('09:34:00',format),
    if ((shifts).length === 1) {

      setsingleShift(shifts[0]);
      setSelectedShift(shifts[0]);
      setTimeout(() => {
        onClickShiftSelected(shifts[0], date);
      }, 500);
    }

    console.log("selected day shifts:", shifts);
    var finalDate = moment(date.directDate, "YYYY-MM-DD[T]HH:mm:ss").format("YYYY-MM-DD")
    holidays?.map((holiday) => {

      var beforeTime = moment(holiday.start_date, "YYYY-MM-DD[T]HH:mm:ss").format("YYYY-MM-DD")
      var afterTime = moment(holiday.end_date, "YYYY-MM-DD[T]HH:mm:ss").format("YYYY-MM-DD")

      if ((moment(beforeTime) <= moment(finalDate)) && (moment(afterTime) >= moment(finalDate))) {
        // if (moment(finalDate).isBetween(beforeTime, afterTime, null, "[]")) {
        if (holiday.is_closed_all_day) {
          setFullTimeBlock(true);
        } else {
          setHolidayBlock(true);
          setHolidayTimeBlock({
            closed_from: holiday.closed_from,
            closed_to: holiday.closed_to,
          });
          console.log("closed_from:", holiday.closed_from,
            "closed_to:", holiday.closed_to)
        }
      }
      return true
    });
  }, [holidays]);

  const onClickShiftSelected = (item, dates) => {
    // if(item.name==undefined || item.name==null)
    // {
    //   item=selectedShift;
    // }
    setTime();
    setShift(item?.name);
    setShiftId(item?.id);
    timesSlot.current.length = 0

    // console.log(moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss"), moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss"), moment(item.start_time, "HH:mm:ss").format("HH:mm:ss"), moment(item.end_time, "HH:mm:ss").format("HH:mm:ss"))
    //   console.log((moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss") <= moment(item.start_time, "HH:mm:ss").format("HH:mm:ss")), (moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss") <= moment(item.end_time, "HH:mm:ss").format("HH:mm:ss")))

    if (((moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss") <= moment(item.start_time, "HH:mm:ss").format("HH:mm:ss")) || (moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss") >= moment(item.start_time, "HH:mm:ss").format("HH:mm:ss"))) && ((moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss") >= moment(item.end_time, "HH:mm:ss").format("HH:mm:ss")) || (moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss") <= moment(item.end_time, "HH:mm:ss").format("HH:mm:ss")))) {
      var x = {
        nextSlot: parseInt(slotSize),
        startTime: (moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss") <= moment(item.start_time, "HH:mm:ss").format("HH:mm:ss")) ? moment(item.start_time, "HH:mm:ss").format("HH:mm:ss") : moment(dates.time.openTime, "hh:mm a").format("HH:mm:ss"),
        endTime: (moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss") >= moment(item.end_time, "HH:mm:ss").format("HH:mm:ss")) ? moment(item.end_time, "HH:mm:ss").format("HH:mm:ss") : moment(dates.time.closingTime, "hh:mm a").format("HH:mm:ss"),
        duration_btn_bookings: parseInt(duration_btn_bookings),
      };
      let slotTime = moment(x.startTime, "HH:mm:ss");
      var endTime = moment(x.endTime, "HH:mm:ss");

      while (slotTime < endTime) {
        timesSlot.current.push({
          time: slotTime.format("hh:mm a"),
          fullTime: slotTime.format("HH:mm:ss"),
        });
        slotTime = slotTime
          .add(x.nextSlot, "minutes")
          .add(x.duration_btn_bookings, "minutes");
      }
      // console.log(timeSlot);
      setTimeSlot(timesSlot.current);
    }

  };
  // holidayBlock &&

  // const [guestlenght,setGuestlenght]=useState([]);

  const onBranchChange = (e) => {
    console.log("selected branch : ", e, chooseBranch)
    setSelectedBranchName(e);
    setSelectedBranch(chooseBranch[e.value]);
    sessionStorage.setItem('currentBranch', JSON.stringify(chooseBranch[e.value]))
    sessionStorage.setItem('selectedBranch', JSON.stringify(e))
    ThemeHandler(chooseBranch[e.value]?.booking_widget_settings?.theme?.primarycolor);
    HandleCloseButton(chooseBranch[e.value]?.booking_widget_settings.display_type)
    renderBranchData(chooseBranch[e.value]);
  }

  const onPeopleChange = (e) => {
    console.log("selected value : ", e)
    setSelectedPeople(e);

  }
  const seldate = () => {
    if (sessionStorage.getItem('selectedDate')) {
      let dates = JSON.parse(sessionStorage.getItem('selectedDate'))
      // onDateSelected(dates)
      let selectdate = document.getElementById(`${dates.Days}`)
      setTimeout(() => {
        selectdate?.click();
      }, 500);

      console.log(dates.Days, selectdate)
    }
    else console.log("failed")
  }

  useEffect(() => {
    const people = async () => {
      let pp = peopleLimit;
      for (let i = 1; i <= pp; i++) {
        await cities.current.push({ name: i })
        // setGuestlenght(prev=>[...prev,{"name":i}])
      }
    }

    window.addEventListener('load', () => {
      seldate();
    });

    people();
  }, [cities, peopleLimit])


  return (
    <Layout pathName={history.location.pathname}>

      <center>
        <Row>
          <Col sm={11} xs={10} className="padding-left">
            <h3 className="pr-reservation-details-title">
              Reservation Details
            </h3>
          </Col>
          <Col className="pl-0 padding-right" xs={2} sm={1}>

            <img
              className="close-button"
              id='close-button'
              src={closeIcon}
              alt="close"
              height={20}
              width={20}
              onClick={() => {
                close();
              }}
            />
          </Col>
        </Row>
        <hr className="mt-0" />
        {isLoading ? <div style={{ height: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img src={loadingGif} alt='loading' />
          <h3>Loading...</h3>
        </div>
          :
          <div>
            <div className="mb-3">
              <Row>
                <Col sm="12" className="text-left padding-none">
                  <dd className="text-bold text-12 pr-reservation-success-label">
                    CHOOSE PLACE WHERE YOU ARE TO BOOK{" "}
                  </dd>
                </Col>
                <Col sm="12" className="text-left padding-none">

                  <Select
                    value={selectedBranchName}
                    options={branchNames}
                    onChange={onBranchChange} optionLabel="name" placeholder="Select Place"
                  />
                  {/* </div> */}

                </Col>
              </Row>
            </div>

            <div className="mb-0">
              <Row>
                <Col sm="12" className="text-left padding-none">
                  <dd className="text-bold text-12 pr-reservation-success-label">
                    SELECT PARTY SIZE{" "}
                    <Link
                      style={{ float: "right" }}
                      to={{
                        pathname: "/contact-us",
                        state: {
                          restaurant_name: hotelName,
                          restaurant_email: hotelEmail,
                          restaurant_mono: hotelMono,
                          rKey: rKey,
                          restId:restId
                        },
                      }}
                    >
                      <p
                        className="more-guests"
                      >
                        More Guests ?
                      </p>
                    </Link>
                  </dd>
                </Col>
                <Col sm="12" className="text-left padding-none">

                  {/* <div className="people-main party-size">
                <div className="text-bold value-main ">{people} PEOPLE</div>
                <div className="right-plus-minus">
                  <span onClick={sub} className="same-circle">
                    -
                  </span>
                  <span onClick={add} className="same-circle">
                    +
                  </span>
                </div>
              </div> */}
                  {/* <div className="dropdownhold"> */}
                  {/* <Dropdown
                  value={selectedPeople}
                  options={guestlenght}
                  onChange={onPeopleChange} optionLabel="name" placeholder="Select party size" /> */}

                  <Select
                    value={selectedPeople}
                    options={guestlenght}
                    onChange={onPeopleChange} optionLabel="name" placeholder="Select party size"
                  />
                  {/* </div> */}

                </Col>
              </Row>
            </div>
            <div className="mb-5 mt-3">
              <Row>
                <Col sm="12" className="text-left padding-none">
                  <dd className=" text-bold text-12 pr-reservation-success-label">
                    DATE
                  </dd>
                </Col>
                <Col sm="12" className="text-left">
                  <div className="slider">
                    <Slider {...settings} className="main-slider-are">
                      {opDays.map((dates) => (
                        <button
                          id={dates.Days}
                          key={dates.Days}
                          onClick={() => {
                            setTime();
                            // sessionStorage.removeItem('reservationBasic')
                            setDateSelected(true);
                            onDateSelected(dates);
                            console.log(dates)
                            sessionStorage.setItem('selectedDate', JSON.stringify(dates))
                            // if ((chooseShift).length === 1) {
                            //   onClickShiftSelected(singleShift, dates);
                            // }
                            // else { onClickShiftSelected(selectedShift, dates); }
                            // setDate(dates.fullDate);
                          }}
                          className={
                            date === dates.fullDate
                              ? "day-time active-inner-grid"
                              : "day-time"
                          }
                        >
                          <span>{dates.Days}</span>
                        </button>
                      ))}
                    </Slider>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-3 mt-3">
              {(chooseShift).length > 1 &&
                <Row>
                  <Col sm="12" className="text-left padding-none">
                    <dd className="text-bold text-12 pr-reservation-success-label">
                      CHOOSE YOUR SHIFT
                    </dd>
                  </Col>

                  <Col sm="12" className="nothing-else padding-none">
                    <div className="grid-for-break">
                      {chooseShift?.map((item, i) => {
                        return (
                          <button
                            key={i}
                            className={
                              item.name === shift
                                ? "inner-grid active-inner-grid"
                                : "inner-grid"
                            }
                            onClick={() => {
                              setSelectedShift(item)
                              if (sessionStorage.getItem('selectedDate')) {
                                let dates = JSON.parse(sessionStorage.getItem('selectedDate'))
                                onClickShiftSelected(item, dates);
                                console.log(item, dates)

                              }
                            }}
                          >
                            {item.name}
                          </button>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              }
            </div>
            <div>
              <div className="mb-2">
                <Row>
                  <Col sm={12} className="padding-none">
                    <Form.Label
                      style={{ fontSize: "12px" }}
                      className="text-bold text-left w-100 f20"
                    >
                      AVAILABLE TIME SLOTS
                    </Form.Label>
                    {fullTimeBlock ? (
                      <div className="no-date-selected">
                        <p>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-shop" viewBox="0 0 16 16">
                            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
                          </svg> &nbsp;
                          Sorry, we are closed for {moment(date, "Do MMMM YYYY").format("Do MMMM")} !</p>
                      </div>
                    ) : (dateSelected && (
                      <div className="grid-for-time-slot">
                        {timeSlot?.map((times, i) => {
                          // console.log(`times`, times);
                          // holidayBlock &&
                          //   moment(times.fullTime, "'HH:mm:ss'").isBetween(
                          //     moment(holidayTimeBlock.closed_from, "HH:mm:ss"),
                          //     moment(holidayTimeBlock.closed_to, "HH:mm:ss"),
                          //     null,
                          //     "[]"
                          //   ) &&
                          //   console.log(`between`);
                          return (moment(date ? date : retrievedObject?.date, "Do MMMM YYYY").isBefore(moment()) &&
                            moment(times.time, "hh:mm a").isBefore(moment())) ||
                            (holidayBlock &&
                              (moment(times.fullTime, "'HH:mm:ss'") >= moment(holidayTimeBlock?.closed_from, "HH:mm:ss") &&
                                moment(times.fullTime, "'HH:mm:ss'") < moment(holidayTimeBlock?.closed_to, "HH:mm:ss")
                              )) ? null : (
                            <button
                              key={i}
                              className={
                                time === times.time
                                  ? "active-inner-grid inner-grid-name"
                                  : "inner-grid-name"
                              }
                              onClick={() => setTime(times.time)}
                            >
                              {times.time}
                            </button>
                          );
                          // return (
                          //   &&
                          //   null
                          // );
                          // return moment(date, "Do MMMM YYYY").isBefore(moment()) ||
                          //   holidayBlock ? (
                          //   moment(times.time, "hh:mm a").isBefore(moment()) ||
                          //   moment(times.fullTime, "'HH:mm:ss'").isBetween(
                          //     moment(holidayTimeBlock?.closed_from, "HH:mm:ss"),
                          //     moment(holidayTimeBlock?.closed_to, "HH:mm:ss"),
                          //     null,
                          //     "[]"
                          //   ) ? null : (
                          //     <button
                          //       className={
                          //         time == times.time
                          //           ? "active-inner-grid inner-grid-name"
                          //           : "inner-grid-name"
                          //       }
                          //       onClick={() => setTime(times.time)}
                          //     >
                          //       {times.time}
                          //     </button>
                          //   )
                          // ) : (
                          //   <button
                          //     className={
                          //       time == times.time
                          //         ? "active-inner-grid inner-grid-name"
                          //         : "inner-grid-name"
                          //     }
                          //     onClick={() => setTime(times.time)}
                          //   >
                          //     {times.time}
                          //   </button>
                          // );
                        })}

                        {/* <button className="inner-grid-name active-day-time">08:00PM</button> */}
                      </div>
                    ))}

                    {timeSlot === null && !fullTimeBlock && (
                      <div className="no-date-selected">
                        <p>
                          Please select a Date/Shift to view available time slots here.
                        </p>
                      </div>
                    )}

                    { }
                  </Col>
                </Row>
              </div>

              {/* <div className="mb-3">
            <div className="summary-box-main">
              <span>Summary:</span>
              {dateSelected ? (
                <p>
                  {selectedPeople?.value} PERSONS, {date}, {time}
                </p>
              ) : (
                <p>NA</p>
              )}
            </div>
          </div> */}

              <div className="mt-3">
                <Row>
                  <Col sm="12" className="text-center padding-none">
                    <Button
                      variant="pr-primary pt-3 pb-3"
                      block
                      disabled={selectedPeople?.value > 0 && shift && date && time ? false : true}
                      onClick={() => {
                        var testObject = {
                          shift: shift,
                          people: selectedPeople?.value,
                          branch: selectedBranchName,
                          date: date,
                          timeSlot: time,
                          shiftId: shiftId,
                        };
                        sessionStorage.setItem(
                          "reservationBasic",
                          JSON.stringify(testObject)
                        );
                        sessionStorage.setItem(
                          "branchNames",
                          JSON.stringify(branchNames)
                        );
                        sessionStorage.setItem("shiftId", shiftId);
                        sessionStorage.setItem(
                          "timeSlot",
                          JSON.stringify(timeSlot)
                        );
                        sessionStorage.setItem(
                          "Shift",
                          JSON.stringify(chooseShift)
                        );
                        sessionStorage.setItem(
                          "branches",
                          JSON.stringify(chooseBranch)
                        );

                        history.push({
                          pathname: "/reservation-details",
                          state: {
                            branch: selectedBranch,
                            shift: shift,
                            people: selectedPeople?.value,
                            date: date,
                            timeSlot: time,
                            slotSize: slotSize,
                            otpEnabled: otpEnabled,
                            shiftId: shiftId,
                            rId: rId,
                            depositeAmount: depositeAmount,
                            depositeMinGuest: depositeMinGuest,
                            rKey: rKey,
                          },
                        });
                        // history.go();
                      }}
                    >
                      CONTINUE
                    </Button>
                  </Col>
                </Row>

              </div>
            </div>
          </div>}
      </center>
    </Layout>
  );
};

export default withRouter(memo(ReservationDetails4));

export const seldate = async () => {
  let dates = JSON.parse(sessionStorage.getItem('selectedDate'))
  // onDateSelected(dates)
  setTimeout(() => {
    let selectdate = document.getElementById(`${dates.Days}`)
    setTimeout(() => {
      selectdate.click();
    }, 500);
    console.log(dates.Days, selectdate)
  }, 100);
  console.log("----BACK BUTTON CLICKED----")
}