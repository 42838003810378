import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import MaskedInput from "react-maskedinput";
import MaskedInputField from "react-maskedinput";
import successCheck from "../assets/images/success-check.svg";
import { Link } from "react-router-dom";
import moment from "moment";
class PaymentSuccess extends Component {

 
  render() {
    const { history } = this.props;

    const {
      firstName,
      lastName,
      date,
      time,
      mobile,
      guest,
      amount,
      restId,
      rKey,
      token,
      reservationId
    } = this.props.location.state;
    const orgId = JSON.parse(sessionStorage.getItem('orgId'))
    console.log(`this.props.location?.state`, this.props.location?.state);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <img src={successCheck} alt="success" height={60} width={60} />
          <h3 className="pr-payment-success-text mt-3">Payment Successful</h3>
          <p className="pt-2 pr-payment-success-details text-center  pb-3">
            Reservation details have been sent to email.<br/>
            Booking ID: {reservationId}
          </p>
          <hr />
          <div className="mb-4">
            <h4 className="text-left font-weight-bold text-bold text-14 text-bold">
              Booking Details
            </h4>
            <Row>
              <Col sm="6" className="text-left">
                <dd className="text-12">
                  {firstName} {lastName}
                </dd>
              </Col>
              <Col sm="6">
                <dd className="text-12 text-right">
                  {moment(date, "Do MMMM YYYY").format("DD-MM-YYYY")}, {time}
                </dd>
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="text-left">
                <dd className="text-12">{mobile}</dd>
              </Col>
            </Row>
            <Row>
              <Col sm="6" className="text-left">
                <dd className="text-12">Guests: {guest}</dd>
              </Col>
            </Row>
          </div>

          <div>
            <Row>
              <Col sm="6" className="text-left">
                <h4 className="font-weight-bold text-14 text-bold">
                  Reservation Amount
                </h4>
                <p className="text-12 pt-0">(Non-Refundable)</p>
              </Col>
              <Col sm="6">
                <dd className="text-12 text-right">£{amount}</dd>
              </Col>
            </Row>
            {/* <Row>
              <Col sm="6" className="text-left">
                <h4 className="font-weight-bold">Tax</h4>
              </Col>
              <Col sm="6">
                <dd className="text-12 text-right">£0.11</dd>
              </Col>
            </Row> */}
            <Row>
              <Col sm="6" className="text-left">
                <h4 className="font-weight-bold text-14 text-bold">
                  Net Amount Paid
                </h4>
              </Col>
              <Col sm="6">
                <dd className="text-12 text-right">£{amount}</dd>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center">
                <Button
                  block
                  variant="outline-pr-primary pt-3 pb-3"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push(`/home/${orgId}`);
                  }}
                >
                  DONE
                </Button>
              </Col>
            </Row>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(PaymentSuccess);
