import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import InputMask from "react-input-mask";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import money from "../assets/images/icons8-money-80.png";
import loading from "../assets/images/icons8-dots-loading.gif";
import { ErrorMessage, Field, Formik, Form as f } from "formik";
import * as yup from "yup";
import valid from "card-validator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
// import moment from "moment";
import { API } from "../common/api";
import close from "../common/close";
import moment from "moment";
import encrypt from './encrypt'
const FormErrorMsg = styled.span`
  color: red;
  float: right;
  font-size: 12px;
`;
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
toast.configure();
class paymentInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: "",
      error: "",
      pay: false,
    };
  }
  handleCloseButton = () => {
    let closeButton = document.getElementById("close-button")
    if(closeButton)
    {
    let widgetType=sessionStorage.getItem("widget_type")||"inline";
    if (widgetType === 'inline') {
      closeButton.style.display = 'none'
    }
    else {
      closeButton.style.display = 'initial'
    }
  }
  }

  componentDidMount()
  {
    this.handleCloseButton();
  }
  handleSubmit = (
    values,
    firstName,
    lastName,
    date,
    time,
    mobile,
    email,
    guest,
    restId,
    shiftId,
    depositeAmount,
    reservation_id,
    history,
    rKey,
    note,
    token
  ) => {
    // console.log("formdata", this.state.formData);

    const expiryDate = values.valid.split("/");

    const APIBody = {
      first_name: firstName,
      last_name: lastName,
      mobile: mobile,
      email: email,
      shift_id: shiftId,
      rest_id: restId,
      booked_date: moment(date, "Do MMMM YYYY").format("YYYY-MM-DD"),
      no_of_guests: guest,
      reserved_from: moment(time, "hh:mm a").format("HH:mm:ss"),
      note: note,
      amount: depositeAmount * guest,
      collect_p:encrypt({
        card_number: values.cardnumber.trim(),
        exp_month:expiryDate[0],
        exp_year: expiryDate[1],
        cvc: values.cvv
      })
    };
    console.log(`APIBody`, APIBody);
    this.setState({pay:true})
    axios
      .post(`${process.env.REACT_APP_BASEURL}/reservation/add_reservation_by_customer`, APIBody)

      .then((res) => {
        console.log("res", res);
        if (res.data.success) {
          this.setState({pay:false})

          history.push({
            pathname: "/payment-success",
            state: {
              firstName: firstName,
              lastName: lastName,
              date: date,
              time: time,
              mobile: mobile,
              guest: guest,
              amount: depositeAmount * guest,
              restId: restId,
              rKey: rKey,
              token: res.data.queue_token_no,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({pay:false})
        toast.error(err.response?.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  handleChange = (value) => {
    console.log(`value`, value);
  };
  render() {
    const { history } = this.props;
    const {
      firstName,
      lastName,
      date,
      time,
      mobile,
      email,
      guest,
      restId,
      shiftId,
      depositeAmount,
      reservation_id,
      rKey,
      note,
      token
    } = this.props.location?.state;
    console.log(`this.props.location?.state`, this.props.location?.state);
    const validationSchema = yup.object().shape({
      cardnumber: yup
        .string()
        .required("Required")
        .test("test-number", "Invalid", (value) => valid.number(value).isValid),
      valid: yup
        .string()

        .required("Required")
        .test(
          "test-number",
          "Invalid",
          (value) => valid.expirationDate(value).isValid
        ),
      cvv: yup
        .string()
        .required("Required")
        .test("test-number", "Invalid", (value) => valid.cvv(value).isValid),
      namecard: yup
        .string()

        .required("Required"),
    });

    return (
      <Formik
        initialValues={{
          cardnumber: "",
          valid: "",
          cvv: "",
          namecard: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          this.handleSubmit(
            values,
            firstName,
            lastName,
            date,
            time,
            mobile,
            email,
            guest,
            restId,
            shiftId,
            depositeAmount,
            reservation_id,
            history,
            rKey,
            note,
            token
          );
        }}
      >
        {({ errors, status, touched, values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Layout pathName={history.location.pathname}>
              <center>
                <Row>
                  <Col className="padding-left" sm={11} xs={10}>
                    <h4 className="pr-reservation-details-title text-left">
                      Payment Information
                    </h4>
                  </Col>
                  <Col className="pl-0 padding-right" sm={1} xs={2}>
                    <img
                    className="close-button"
                    id='close-button'
                      src={closeIcon}
                      alt="card"
                      height={20}
                      width={20}
                      onClick={() => {
                        close();
                      }}
                    />
                  </Col>
                </Row>
                <hr className="mt-0" />

                <div className="mb-2 mt-3">
                  <Row className="mb-2">
                    <Col sm="12" className="text-left padding-none">
                      <Form.Label style={{ width: "100%" }}>CARD NUMBER <ErrorMessage
                        name="cardnumber"
                        component={FormErrorMsg}
                        style={{ float: "right" }}
                      /></Form.Label>
                      <br />

                    </Col>
                    <Col sm="12" className="text-left  padding-none">
                      <Field
                        // as={MaskedInput}
                        // pattern="[0-9]*"
                        name="cardnumber"
                        style={{ marginTop: "5px" }}
                        as={InputMask}
                        maskChar={null}
                        type="text"
                        // maskChar="_"
                        // mask="1111 1111 1111 1111"
                        // size={20}
                        mask="9999 9999 9999 9999"
                        // value={this.state.formData.cardnumber}
                        className={
                          errors.cardnumber && touched.cardnumber
                            ? " is-invalid form-control"
                            : "pr-0 comman-input form-control"
                        }
                      // type="text"
                      // size="16"
                      // value={values.cardnumber}
                      // onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <div className="mb-3">
                    <Row>
                      <Col sm="6" className="text-left padding-left">
                        <Form.Label>VALID TILL (MM/YY)</Form.Label>
                        <br />
                        <ErrorMessage name="valid" component={FormErrorMsg} />

                        <Field
                          name="valid"
                          style={{ marginTop: "16px" }}
                          className={
                            errors.valid && touched.valid
                              ? "is-invalid form-control"
                              : "pr-0 comman-input form-control"
                          }
                          as={InputMask}
                          mask="99/9999"
                          type="text"
                          maskChar={null}
                          placeholder="12/2023"
                        // value={this.state.formData?.valid}
                        // onChange={(e) =>
                        //   this.setState({
                        //     formData: {
                        //       ...this.state.formData,
                        //       valid: e.target.value,
                        //     },
                        //   })
                        // }
                        />
                      </Col>
                      <Col sm="6" className="text-left padding-right">
                        <Form.Label>CVV</Form.Label>
                        <br />
                        <ErrorMessage name="cvv" component={FormErrorMsg} />

                        <Field
                          name="cvv"
                          style={{ marginTop: "16px" }}
                          className={
                            errors.cvv && touched.cvv
                              ? " is-invalid form-control"
                              : "pr-0 comman-input form-control"
                          }
                          as={InputMask}
                          type="text"
                          maskChar={null}
                          placeholder="001"
                          mask="999"
                        // value={this.state.formData?.cvv}
                        // onChange={(e) =>
                        //   this.setState({
                        //     formData: {
                        //       ...this.state.formData,
                        //       cvv: e.target.value,
                        //     },
                        //   })
                        // }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-0">
                    <Row className="mb-2">
                      <Col sm="12" className="text-left padding-left">
                        <dd className="text-12 pr-reservation-success-label">
                          NAME ON THE CARD
                          <ErrorMessage
                            name="namecard"
                            component={FormErrorMsg}
                          />

                        </dd>
                      </Col>
                      <Col sm="12" className="text-right padding-right">

                        <Field
                          style={{ marginTop: "5px" }}
                          name="namecard"
                          className={
                            errors.namecard && touched.namecard
                              ? " is-invalid"
                              : "pr-0 comman-input"
                          }
                          as={Form.Control}
                          type="text"
                          placeholder="NAME ON THE CARD"
                        // value={this.state.formData?.namecard}
                        // onChange={(e) =>
                        //   this.setState({
                        //     formData: {
                        //       ...this.state.formData,
                        //       namecard: e.target.value,
                        //     },
                        //   })
                        // }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-2">
                    <Row>
                      <Col sm={12} className="padding-none">
                        <div className="stripe-card-main">
                          <div className="left-col-name">Stripe</div>
                          <div className="right-content">
                            Your payment information are secure and not sharing
                            with third parties
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col sm="6" className="text-left padding-left">
                        <Button
                          variant="outline-pr-primary pt-3 pb-3"
                          block
                          onClick={() => {
                            history.goBack();
                          }}
                        >
                          BACK
                        </Button>
                      </Col>
                      <Col sm="6" className="text-left padding-right">
                        <Button
                          type="submit"
                          variant="pr-primary pt-3 pb-3"
                          block
                          disabled={
                            values.cardnumber &&
                              values.valid &&
                              values.cvv &&
                              values.namecard
                              ? false
                              : true
                          }
                        // onClick={() => {
                        //   this.onClickPayNow(
                        //     history,
                        //     firstName,
                        //     lastName,
                        //     date,
                        //     time,
                        //     mobile,
                        //     guest,
                        //     restId,
                        //     depositeAmount,
                        //     reservation_id
                        //   );
                        //   // history.push("/payment-success");
                        // }}
                        >
                          PAY NOW
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </center>
              {this.state.pay?
              <center className="pay">
                <img className="money" src={money} alt="money" />
                <p>Payment Initiated</p>
                <img className="dots" src={loading} alt="money" />

              </center>:null}
            </Layout>
          </form>
        )}
      </Formik>
    );
  }
}

export default withRouter(paymentInformation);
