import React, {Component} from 'react';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {Link} from "react-router-dom";
import { withRouter } from "react-router";
import Layout from "../components/Layout/Layout.js";


class prHome extends Component {
    render() {
        const {history} = this.props
        return (
            <Layout pathName={history.location.pathname}>
                <Button className="pr-main-buttons"
                onClick={()=> {history.push("/reservation-details4")}}
                        size="lg"
                        block>
                    Continue as guest
                </Button>
                <Button className="pr-main-buttons mt-3"
                        size="lg"
                        block
                        onClick={() => {history.push("/mobile-verification")}}>
                    Login with OTP
                </Button>
            </Layout>
        );
    }
}

export default withRouter(prHome);
