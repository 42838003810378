import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import Slider from "react-slick";
import { InputNumber } from "primereact/inputnumber";

class Choosetime extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
    };
    const { history } = this.props;
    console.log("history history", history);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11}></Col>
            <Col className="pl-0" sm={1}>
              <img id='close-button' className="close-button" alt="close" src={closeIcon} height={20} width={20} />
            </Col>
          </Row>

          <div className="mb-4">
            <Row>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-label">
                  CHOOSE YOUR TIME
                </dd>
              </Col>
              <Col sm="12" className="nothing-else">
                <div className="grid-for-break">
                  <div className="inner-grid">Breakfast</div>
                  <div className="inner-grid">Lunch</div>
                  <div className="inner-grid">Dinner</div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mb-4">
            <Row>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-label">
                  CHOOSE NUMBER OF GUESTS
                </dd>
              </Col>
              <Col sm="12" className="text-left">
                {/* <div className="people-main">
                                            <div className="value-main">0 PEOPLE</div>
                                            <div className="right-plus-minus">
                                                <span className="same-circle">-</span>
                                                <span className="same-circle">+</span>
                                            </div>

                                    </div> */}
                {/* <InputNumber id="horizontal" name="above" className="numinc" value={guestCount} onChange={e => Guest(e)} showButtons buttonLayout="horizontal"
                                    decrementButtonClassName="p-inpnumber-button" incrementButtonClassName="p-inpnumber-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" /> */}
              </Col>
            </Row>
          </div>
          <div className="mb-5">
            <Row>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-label">DATE</dd>
              </Col>
              <Col sm="12" className="text-left">
                <div className="slider">
                  <Slider {...settings} className="main-slider-are">
                    <div className="day-time">
                      <span>20</span>
                      <span>MON</span>
                    </div>
                    <div className="day-time">
                      <span>21</span>
                      <span>TUE</span>
                    </div>
                    <div className="day-time">
                      <span>22</span>
                      <span>WED</span>
                    </div>
                    <div className="day-time">
                      <span>23</span>
                      <span>THU</span>
                    </div>
                    <div className="day-time">
                      <span>24</span>
                      <span>FRI</span>
                    </div>
                    <div className="day-time">
                      <span>25</span>
                      <span>SAT</span>
                    </div>
                    <div className="day-time">
                      <span>26</span>
                      <span>SUN</span>
                    </div>
                  </Slider>
                </div>
              </Col>
            </Row>
          </div>

          <div>
            <div className="mb-3">
              <Row>
                <Col sm={12}>
                  <Form.Label className="text-left w-100">
                    AVAILABLE TIME SLOT
                  </Form.Label>
                  <div className="summary-box-main text-center please-select">
                    Please select date and persons to view available time slots
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <div className="summary-box-main">
                <span>Summary:</span>
                <p>NA</p>
              </div>
            </div>

            <div>
              <Row>
                <Col sm="12" className="text-center">
                  <Button
                    variant="pr-primary pt-3 pb-3"
                    block
                    onClick={() => {
                      history.push("/reservation-details");
                    }}
                  >
                    CONTINUE
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(Choosetime);
