import React, { Component, useEffect, useState, useRef, memo, useCallback } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import {withRouter} from "react-router";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg"


class ReservationDetails3 extends Component {
    inlineWidget=null
    
    componentDidMount() {
        let closeButton = document.getElementById("close-button")
        if(closeButton)
        {
            let widgetType=sessionStorage.getItem("widget_type")||"inline";
            if (widgetType === 'inline') {
            closeButton.style.display = 'none'
            }
            else {
            closeButton.style.display = 'initial'
            }
        }
    }
    render() {
       

        const {history} = this.props
       
        console.log("history history",history, this.state)
          
        return (
            <Layout pathName={history.location.pathname}>
                <center>
                    <Row>
                        <Col className="padding-left" sm={11}>
                            <h4 className="pr-reservation-details-title">Reservation Details</h4>
                        </Col>
                        <Col className="pl-0 padding-right" sm={1}>
                            <img className="close-button" id='close-button' alt="close" src={closeIcon} height={20} width={20}/>
                        </Col>
                    </Row>
                    <hr className="mt-0"/>
                    <div className="mb-3">
                        <Row>
                            <Col sm="12" className="text-left padding-none">
                                <dd className="text-12 pr-reservation-success-label">CUSTOMER NAME</dd>
                            </Col>
                            <Col sm="12" className="text-left padding-none">
                                <dd className="text-12 blue-color">Calvin Harris</dd>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-3">
                        <Row>
                            <Col sm="12" className="text-left padding-none">
                                <dd className="text-12 pr-reservation-success-label">PHONE NUMBER</dd>
                            </Col>
                            <Col sm="12" className="text-left padding-none">
                                <dd className="text-12 blue-color">(044) 667 7789</dd>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <div className="mb-3">
                            <Row>
                                <Col sm={12} className="padding-none">
                                <Form.Label className="text-left w-100">RESERVATION NOTES</Form.Label>
                                <Form.Control className="comman-input" as="textarea" rows={3} />
                                </Col>
                            </Row>
                        </div>
                        <div className="mb-3">
                            <div className="summary-box-main">
                                    <span>Summary:</span>
                                    <p>3 persons, 21th December 2020, 02:00 PM</p>
                            </div>
                        </div>

                        <div>
                            <Row>
                                <Col sm="6" className="text-left padding-left">
                                    <Button variant="outline-pr-primary pt-3 pb-3" block>BACK</Button>
                                </Col>
                                <Col sm="6" className="text-left padding-right">
                                    <Button variant="pr-primary pt-3 pb-3" block>RESERVE NOW</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </center>
            </Layout>
        );

        
    }

    
}

export default withRouter(ReservationDetails3);