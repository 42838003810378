import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import MaskedInput from "react-maskedinput";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import closeIcon from "../assets/images/close-icon.svg";
import { Captcha, captchaSettings } from "reactjs-captcha";

class ContactUs extends Component {
  handleCloseButton = () => {
    let closeButton = document.getElementById("close-button")
    let widgetType=sessionStorage.getItem("widget_type")||"inline";
    if (widgetType === 'inline') {
      closeButton.style.display = 'none'
    }
    else {
      closeButton.style.display = 'initial'
    }
  }

  componentDidMount()
  {
    this.handleCloseButton();
  }
  render() {
    const { history } = this.props;
    const { restaurant_name, restaurant_email, restaurant_mono, restId } =
      this.props.location.state;
    console.log("history history", history);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <Row>
            <Col sm={11}>
              <h4
                className="contacttitle"
                style={{ fontSize: "18px !important" }}
              >
                Contact Us
              </h4>
            </Col>
            <Col className="pl-0" sm={1}>
              <img id='close-button' className="close-button" src={closeIcon} alt="close" height={20} width={20} />
            </Col>
          </Row>
          <hr className="mt-0" />
          <div className="mb-3">
            <Row>
              <Col sm="12" className="text-left">
                <dd className="text-12 pr-reservation-success-label try-another text-bold">
                  For More Guests Please Contact
                  <br />
                  ({restaurant_name})
                  {/* <br/>Can't read this?  <a href="#" title="try another"> Try another.</a> */}
                </dd>
              </Col>
            </Row>
          </div>

          <div>
            <div className="mb-3">
              <Row>
                <Col sm="12" className="text-left">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#787878",
                      fontWeight: "500",
                    }}
                  >
                    Phone : <a href={`tel:${restaurant_mono}`}>{restaurant_mono}</a>
                  </span>
                </Col>
                <Col
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  sm="12"
                  className="text-left"
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#787878",
                      fontWeight: "500",
                    }}
                  >
                    Email : <a href={`mailto:${restaurant_email}`}>{restaurant_email}</a>
                  </span>

                  {/* <input  type="text"  readOnly id="capt"/>
                                        <input  type="text" id="textinput" /> */}
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Button
                className="pr-mobile-input-button filled"
                size="sm"
                variant="pr-primary"
                onClick={() => {
                  history.push(`/home/${restId}`);
                }}
              >
                BACK
              </Button>
            </div>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(ContactUs);
