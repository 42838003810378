import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import MaskedInput from "react-maskedinput";
import MaskedInputField from "react-maskedinput";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
const FormErrorMsg = styled.span`
  color: red;
  float: right;
  font-size: 12px;
`;

class MobileInput extends Component {
  render() {
    const { history } = this.props;
    console.log("history history", history);
    return (
      <Formik
        initialValues={{ card: "" }}
        validationSchema={yup.object({
          card: yup
            .string()
            .min(5, "should be more then 5 characters")
            .required("Required"),
        })}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          resetForm({ values: "" });
        }}
      >
        {({ errors, status, touched }) => (
          <Layout pathName={history.location.pathname}>
            <center>
              <h3 className="font-weight-bold mb-5">
                Please enter your registered mobile number to receive OTP
              </h3>
              <Form>
                <Form.Label className="pr-mobile-input-label">
                  Phone Number
                </Form.Label>
                <Field
                  as={MaskedInput && Form.Control}
                  className={
                    errors.card && touched.card ? " is-invalid" : "form-control"
                  }
                  mask="(111) 111 1111"
                  name="card"
                  size="20"
                  onChange={this._onChange}
                />
                <Button
                  className="pr-mobile-input-button"
                  size="sm"
                  variant="pr-primary"
                  onClick={() => {
                    history.push("/verify-mobile");
                  }}
                >
                  SUBMIT
                </Button>
              </Form>
            </center>
          </Layout>
        )}
      </Formik>
    );
  }
}

export default withRouter(MobileInput);
