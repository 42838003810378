import React from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51IYPtuSJG6uBi4srac6SRuG8fs0XN1HaULf2wrLbp3yzSU0gAE7s3VMIUEDKeNZq9Qdkv2RZlXkMYsoR0VvntSAe00PdF7IhUs"
);
const skStripe = require("stripe")(
  "sk_test_51IYPtuSJG6uBi4srpcK3tHVPVcO2z0nR5h17R1sm4KRU6VcGxhxy4jtrVSThLK3WjUvR6jhOl0IreLxJNROZt6zn00RNqdeCLh"
);

const handleStripePayment = async (args) => {
  // Get Stripe.js instance
  //
  // console.log("args", args);
  // const session = await stripe.checkout.sessions.create({
  //   payment_method_types: ["card"],
  //   line_items: [
  //     {
  //       price_data: {
  //         currency: "usd",
  //         product_data: {
  //           name: "T-shirt",
  //         },
  //         unit_amount: 2000,
  //       },
  //       quantity: 1,
  //     },
  //   ],
  //   mode: "payment",
  //   success_url: "https://example.com/success",
  //   cancel_url: "https://example.com/cancel",
  // });
  // console.log(`session`, session);
  // return;
  // console.log(args);

  const successUrl = `${window.location.protocol}//${window.location.host}/payment-success/${args.firstName}/${args.lastName}/${args.date}/${args.time}/${args.mobile}/${args.guest}`;
  console.log(`successUrl`, successUrl);
  const stripe = await stripePromise;
  const result = await stripe
    .redirectToCheckout({
      //   sessionId: session.id,
      mode: "payment",

      billingAddressCollection: "auto",

      lineItems: [
        {
          price: "price_1IqEGVSJG6uBi4sr5BA1d9BJ",
          quantity: args.guest ? args.guest : 1,
        },
      ],
      successUrl: successUrl.replace(/ /g, "%20"),
      cancelUrl: `${
        window.location.protocol + "//" + window.location.host
      }/payment-error`,
    })
    .then((res) => {
      return true;
    })
    .catch((err) => {
      console.log(`err`, err);
    });
  prompt(result);
  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    console.log("error", result.error);
  }
};

export default handleStripePayment;
