import './App.css';
import "./assets/scss/styles.scss"
import React, { useEffect } from "react";
import Router from "./routes/router.js"
import ThemeHandler from './Theme'
import { BrowserRouter } from "react-router-dom";

function App() {
 

  return (
    <div className="App">
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
