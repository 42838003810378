
export default function ThemeHandler(primary) {

    if (primary) {
        document.documentElement.style.setProperty('--primary-color', primary);
    }
    else {
        document.documentElement.style.setProperty('--primary-color', '#000'); //fallback color
    }

}