import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import MaskedInput from "react-maskedinput";
import MaskedInputField from "react-maskedinput";
import successCheck from "../assets/images/success-check.svg";

class ReserveSuccess extends Component {

 
  render() {
    const { history } = this.props;
    const { firstName, lastName, date, time, mobile, guest, restId, rKey,token, reservationId } =
      this.props.location.state;
    
    const orgId = JSON.parse(sessionStorage.getItem('orgId'))
    console.log("history history", history);

  
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <img src={successCheck} height={60} width={60} />
          <h3 className="pr-payment-success-text mt-3">
            Reservation Successful
          </h3>
          <p className="pt-2 pr-payment-success-details text-center  pb-3">
          Reservation details have been sent to email.<br/>
            Booking ID: {reservationId}
          </p>
          <hr />
          <div className="mb-2">
            <Row>
              <Col sm="12" className="text-center">
                <dd className="text-12 pr-reservation-success-label">Name</dd>
              </Col>
              <Col sm="12">
                <dd className="text-12 text-center pr-reservation-success-value">
                  {firstName} {lastName}
                </dd>
              </Col>
            </Row>
          </div>

          <div className="mb-2">
            <Row>
              <Col sm="12" className="text-center">
                <dd className="text-12 pr-reservation-success-label">Date</dd>
              </Col>
              <Col sm="12">
                <dd className="text-12 text-center pr-reservation-success-value">
                  {date}, {time}
                </dd>
              </Col>
            </Row>
          </div>

          <div className="mb-2">
            <Row>
              <Col sm="12" className="text-center">
                <dd className="text-12 pr-reservation-success-label">Phone</dd>
              </Col>
              <Col sm="12">
                <dd className="text-12 text-center pr-reservation-success-value">
                  {mobile}
                </dd>
              </Col>
            </Row>
          </div>

          <div className="mb-2">
            <Row>
              <Col sm="12" className="text-center">
                <dd className="text-12 pr-reservation-success-label">Guests</dd>
              </Col>
              <Col sm="12">
                <dd className="text-12 text-center pr-reservation-success-value">
                  {guest}
                </dd>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center">
              <Button
                  block
                  variant="outline-pr-primary pt-3 pb-3"
                  onClick={() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    history.push(`/home/${orgId}`);
                  }}
                >
                 DONE
                </Button>
              </Col>
            </Row>
            
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(ReserveSuccess);
