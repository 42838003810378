import React, { Component, useMemo } from "react";
import { Route } from "react-router-dom";
import Home from "../pages/Home.js";
import MobileInput from "../pages/MobileInput.js";
import PaymentSuccess from "../pages/paymentSuccess.js";
import ReservationDetails from "../pages/reservationDetails.js";
import ReserveSuccess from "../pages/reservationSuccess.js";
import verifyOTP from "../pages/verifyOTP.js";
import ReservationConfirmation from "../pages/ReservationConfirmation.js";
import paymentInformation from "../pages/paymentInformation.js";
import reservationDetails3 from "../pages/reservationDetails3.js";
import Choosetime from "../pages/Choosetime.js";
import ReservationBasic from "../pages/reservationBasic.js";
import reservationDetails5 from "../pages/reservationDetails5.js";
import Securitycheck from "../pages/Securitycheck.js";
import ContactUs from "../pages/ContactUs.js";
import paymentError from "../pages/paymentError.js";
import Encrypt from '../pages/encrypt'


function Router() {
  const reservationBasic = React.useMemo(() => <ReservationBasic />, [])
  return <>
    {/* <Route exact path="/home" component={Home} /> */}
    <Route exact path="/mobile-verification" component={MobileInput} />
    <Route exact path="/verify-mobile" component={verifyOTP} />
    <Route path="/payment-success" component={PaymentSuccess} />
    {/* <Route exact path="/payment-error" component={PaymentError} /> */}
    <Route exact path="/reservation-success" component={ReserveSuccess} />
    {/* second screen */}
    <Route
      exact
      path="/reservation-details"
      component={ReservationDetails}
    />
    {/* reservation confiramation screen */}
    <Route
      exact
      path="/reservation-confirmation"
      component={ReservationConfirmation}
    />
    <Route
      exact
      path="/payment-informations"
      component={paymentInformation}
    />
    <Route
      exact
      path="/reservation-details3"
      component={reservationDetails3}
    />
    <Route exact path="/home/:orgId" >
      {reservationBasic}
    </Route>
    <Route
      exact
      path="/reservation-details5"
      component={reservationDetails5}
    />
    <Route exact path="/choose-times" component={Choosetime} />
    <Route exact path="/security-check" component={Securitycheck} />
    <Route exact path="/contact-us" component={ContactUs} />
    <Route exact path="/payment-error" component={paymentError} />
    {/* <Route exact path="/encrypt" component={Encrypt} /> */}
  </>
}

export default Router;
