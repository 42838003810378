import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../components/Layout/Layout.js";
import MaskedInput from "react-maskedinput";
import MaskedInputField from "react-maskedinput";
import error from "../assets/images/error.svg";
import { Link } from "react-router-dom";

class PaymentError extends Component {
  render() {
    const { history } = this.props;

    const persons = [{ title: "Apple", count: 0, price: 100 }];

    console.log("history history", history);
    return (
      <Layout pathName={history.location.pathname}>
        <center>
          <img src={error} alt="success" height={60} width={60} />
          <h3 className="pr-payment-success-text mt-3">Error</h3>
          <p className="pt-2 pr-payment-success-details text-center  pb-3">
            Please try again.
          </p>
          <hr />

          <div>
            <Row>
              <Col sm="12" className="text-center">
                <Link to="/" className="go-back">
                  Go Back To Home Screen
                </Link>
              </Col>
            </Row>
          </div>
        </center>
      </Layout>
    );
  }
}

export default withRouter(PaymentError);
